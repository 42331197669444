@import "src/styles/helpers";

.details {
  padding-top: var(--margin-xxl);
  padding-bottom: var(--margin-xxl);

  @include media(tablet) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @include media(landscape) {
    @at-root .simple#{&} {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }

  @include media(desktopAll) {
    padding-top: 104px;
    padding-bottom: 104px;

    @at-root .simple#{&} {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 40px;
    font-size: 14px;
    line-height: 2;
    font-weight: 300;

    @at-root .simple#{&} {
      font-size: 16px;
      letter-spacing: var(--ls-183);
    }

    @include media(mobile) {
      margin-top: 20px;

      @at-root .simple#{&} {
        margin-top: 32px;
      }
    }

    @include media(portrait) {
      @at-root .simple#{&} {
        margin-top: 32px;
      }
    }

    @include media(landscape) {
      @at-root .simple#{&} {
        margin-top: 56px;
      }
    }
  }

  .image {
    position: relative;
    width: 100%;
    margin: calc(var(--margin-xl) + var(--margin-sm)) 0;

    @at-root .simple#{&} {
      margin: calc(var(--margin-md) + var(--margin-lg)) 0 0;
    }

    @include media(portrait) {
      margin: calc(56px + var(--margin-sm)) 0;

      @at-root .simple#{&} {
        margin: calc(var(--margin-md) + var(--margin-lg)) 0 0;
      }
    }

    @include media(landscape) {
      @at-root .simple#{&} {
        margin: 92px 0 0;
      }
    }

    @include media(desktopAll) {
      @at-root .simple#{&} {
        margin: 92px 0 0;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .header {

    @include media(tablet) {
      display: grid;
      gap: 56px;
    }

    @include media(portrait) {
      grid-template-columns: 1fr 327px;

      @at-root .simple#{&} {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }

    @include media(landscape) {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      @at-root .simple#{&} {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }

    @include media(desktopAll) {
      display: grid;
      gap: 56px;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      @at-root .simple#{&} {
        grid-template-columns: 420px auto;
        gap: 100px;
      }
    }
  }

  .title {
    font-size: 28px;
    line-height: 36px;

    @include media(portrait) {
      font-size: 32px;
      line-height: 40px;

      @at-root .simple#{&} {
        font-size: 28px;
        line-height: 36px;
      }
    }

    @include media(landscape) {
      font-size: 32px;
      line-height: 40px;

      @at-root .simple#{&} {
        font-size: 40px;
        line-height: 48px;
      }
    }

    @include media(desktopAll) {
      @at-root .simple#{&} {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }
}

.technologies {

  @include media(landscape) {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 72px;
  }

  @include media(desktopAll) {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 72px;
  }

  &__title {
    text-align: center;
    margin-bottom: var(--margin-lg);

    @include media(tablet) {
      font-size: 20px;
      line-height: 28px;
    }

    @include media(landscape) {
      text-align: left;
    }

    @include media(desktopAll) {
      text-align: left;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 2;
    text-align: center;
    margin-bottom: 42px;
    font-weight: 300;

    @include media(portrait) {
      margin-bottom: 72px;
    }

    @include media(landscape) {
      text-align: left;
    }

    @include media(desktopAll) {
      text-align: left;
    }
  }

  &__content {

    @include media(portrait) {
      max-width: 484px;
      margin: 0 auto;
    }

    @include media(landscape) {
      max-width: 500px;
    }

    @include media(desktopSmall) {
      max-width: 500px;
    }

    @include media(desktop) {
      max-width: 500px;
    }

    @include media(desktopBig) {
      max-width: 700px;
    }

    @include media(desktopHuge) {
      max-width: 700px;
    }
  }

  &__items {
    display: inline-grid;
    justify-content: flex-start;
    grid-auto-flow: column;
    padding-top: 26px;
    padding-bottom: 26px;
    max-width: fit-content;

    &__wrap {
      @include scrollOutOfContainer;
      @include hideScrollbar;
      text-align: center;

      @include media(landscape) {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
  }
}

.technology {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  position: relative;
  @include setProperty(color, var(--secondary-100), var(--secondary-300));

  &:nth-child(odd) {

    .technology {

      &__title {
        top: -26px;
      }
    }
  }

  &:nth-child(even) {
    margin-top: 56px;

    .technology {

      &__title {
        bottom: -26px;
      }
    }
  }

  &__title {
    position: absolute;
    max-width: 200%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__icon {
    width: 56px;
    height: 56px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
@import "src/styles/helpers";

.stats {
  padding-top: var(--margin-xxl);
  padding-bottom: var(--margin-xxl);

  @include media(tablet) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @include media(desktopAll) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  &__content {
    margin-top: 56px;
    display: grid;
    grid-auto-rows: 1fr;
    gap: var(--margin-xs);

    @include media(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media(desktopAll) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

.title {
  @include media(tablet) {
    font-size: 32px;
    line-height: 40px;
  }
}

.tile {
  border: 1px solid;
  @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
  padding: var(--margin-md);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media(tablet) {
    height: 242px;
    padding: var(--margin-lg);

    &:nth-child(3) {
      grid-column: 2 / 3;
    }

    &:nth-child(5) {
      grid-column: 1 / 2;
    }
  }

  @include media(desktopAll) {
    height: 242px;

    &:nth-child(3) {
      grid-column: 4 / 5;
    }

    &:nth-child(5) {
      grid-column: 3 / 4;
    }
  }

  &__title {
    font-family: 'KharkivTone', sans-serif;
    font-size: 24px;
    line-height: 32px;

    @include media(landscape) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__value {
    font-size: 14px;
    line-height: 2;
    letter-spacing: var(--ls-183);
    font-weight: 300;
  }
}

.icon {
  margin-bottom: 30px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include setProperty(color, var(--primary-500), var(--primary-900));

  svg {
    height: 100%;
    width: 100%;
  }
}

.startProjectButton {
  @include setProperty(border-color, var(--primary-500), var(--primary-900));
  @include setProperty(color, var(--primary-500), var(--primary-900));

  &__title {
    font-family: 'KharkivTone', sans-serif;
    font-size: 20px;
    line-height: 28px;

    @include media(landscape) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &:hover {
    @include setProperty(border-color, var(--primary-300), var(--primary-700));
    @include setProperty(color, var(--primary-300), var(--primary-700));
  }
}

@import "src/styles/helpers";

.submenuItem {
  display: flex;
  align-items: center;
  gap: 12px;

  .imageWrapper {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .image {
    position: relative;
    width: 24px;
    height: 24px;
    transition: 0.3s ease all;
  }

  .title {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-family: "KharkivTone", sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    transition: 0.3s ease all;
  }

  &:hover {
    .imageWrapper {
      @include setProperty(
        background,
        linear-gradient(
          90deg,
          rgba(246, 255, 254, 0) -33.37%,
          rgba(155, 188, 183, 0.04) 35.32%,
          rgba(246, 255, 254, 0) 118.57%
        ),
        transparent
      );
    }

    .image {
      transform: scale(1.25);
    }

    .title {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }
  }
}

@import "src/styles/helpers";

.article {
  @include pageHeaderPadding;
  overflow-x: hidden;

  figure[class='table'] {
    overflow-x: auto;
  }

  :global {

    .container {

      @include media(desktopSmall) {
        padding-left: 72px !important;
        padding-right: 72px !important;
      }

      @include media(desktop) {
        width: 1200px !important;
      }

      @include media(desktopHuge) {
        width: 1760px !important;
      }
    }
  }
}

.heroImage {
  margin-bottom: var(--margin-lg);

  @include media(mobile) {
    padding: 0 0 !important;
  }

  @include media(portrait) {
    margin-bottom: var(--margin-xxl);
  }

  @include media(landscape) {
    margin-bottom: 120px;
  }

  @include media(desktopAll) {
    margin-bottom: 120px;
  }

  &_image {
    position: relative;
    width: 100%;
    height: 240px;
    border-radius: 0;
    @include setProperty(outline-color, var(--secondary-500), var(--secondary-50));

    &__round {
      --outline-widht: 0;
      --margin-top: 0;

      outline: var(--outline-widht) solid;
      margin-top: calc(var(--outline-widht) + var(--margin-top));
      overflow: hidden;

      @include media(notMobile) {
        border-radius: 286px;
      }
    }

    &__square {
      position: relative;

      @include media(mobile) {
        margin-top: var(--margin-md);
      }
    }

    @include media(mobile) {
      margin-top: 24px;
    }

    @include media(portrait) {
      &__square {
        height: 424px;
      }

      &__round {
        --outline-widht: 12px;
        --margin-top: 38px;
        height: 218px;
      }
    }

    @include media(landscape) {
      margin-bottom: 120px;

      &__square {
        height: 498px;
      }

      &__round {
        --outline-widht: 16px;
        --margin-top: 58px;
        height: 262px;
      }
    }

    @include media(desktopSmall) {
      &__square {
        height: 498px;
      }

      &__round {
        --margin-top: 74px;
        height: 240px;
      }
    }

    @include media(desktop) {
      &__square {
        height: 498px;
      }

      &__round {
        --margin-top: 74px;
        height: 240px;
      }
    }

    @include media(desktopBig) {
      &__square {
        height: 620px;
      }

      &__round {
        --margin-top: 94px;
        height: 320px;
      }
    }

    @include media(desktopHuge) {
      &__square {
        height: 798px;
      }

      &__round {
        --margin-top: 94px;
        height: 436px;
      }
    }

    @include media(desktopAll) {
      &__round {
        --outline-widht: 16px;
      }
    }

    img {
      object-fit: cover;
    }
  }
}

.hero {
  margin-top: var(--margin-lg);
  padding-bottom: 80px;

  @include media(portrait) {
    margin-top: var(--margin-xxl);
    padding-bottom: 92px;
  }

  @include media(landscape) {
    margin-top: 120px;
    padding-bottom: 112px;
  }

  @include media(desktopAll) {
    margin-top: 120px;
    padding-bottom: 100px;
  }

  @include media(desktopBig) {
    padding-bottom: 124px;
  }

  @include media(desktopHuge) {
    padding-bottom: 124px;
  }
}

.main {
  @include media(desktopAll) {
    display: grid;
    padding-bottom: 104px;
  }

  @include media(landscape) {
    margin-left: 98px;
    margin-right: 98px;
  }

  @include media(desktopSmall) {
    grid-template-columns: 274px minmax(0, 1fr);
    gap: 94px;
  }

  @include media(desktop) {
    grid-template-columns: 314px minmax(0, 1fr);
    gap: 95px;
  }

  @include media(desktopBig) {
    grid-template-columns: 334px minmax(0, 1fr);
    gap: 107px;
  }

  @include media(desktopHuge) {
    grid-template-columns: 422px minmax(0, 1fr);
    gap: 153px;
  }
}

.mainContent {
  position: relative;

  @include media(desktopAll) {
    display: grid;
    align-items: flex-start;
    grid-template-columns: minmax(0, 1fr) auto;
  }

  @include media(desktopSmall) {
    gap: 95px;
  }

  @include media(desktop) {
    gap: 95px;
  }

  @include media(desktopBig) {
    gap: 107px;
  }

  @include media(desktopHuge) {
    gap: 153px;
  }
}

.sidebar {
  @include media(desktopAll) {
    display: grid;
    grid-template-rows: 1fr auto;
    gap: 32px;
    position: relative;
  }
}

.articleContent {
  position: relative;

  &__vectorStart {
    position: absolute;
    left: -15px;
    top: -32px;
    width: 86px;
    height: 91px;
    @include setProperty(color, var(--dark-line-low-accent), var(--light-line-low-accent));
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
    }

    @include media(portrait) {
      width: 149px;
      height: 158px;
      left: -41px;
      top: -58px;
    }

    @include media(landscape) {
      width: 126px;
      height: 135px;
      left: -42px;
      top: -47px;
    }

    @include media(desktopSmall) {
      width: 149px;
      height: 158px;
      left: -48px;
      top: -58px;
    }

    @include media(desktop) {
      width: 149px;
      height: 158px;
      left: -48px;
      top: -58px;
    }

    @include media(desktopBig) {
      width: 164px;
      height: 158px;
      left: -56px;
      top: -60px;
    }

    @include media(desktopHuge) {
      width: 251px;
      height: 266px;
      left: -102px;
      top: -110px;
    }
  }

  &__vectorEnd {
    position: absolute;
    right: -24px;
    bottom: -20px;
    width: 131px;
    height: 87px;
    @include setProperty(color, var(--dark-line-low-accent), var(--light-line-low-accent));
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
    }

    @include media(tablet) {
      bottom: -30px;
    }

    @include media(desktopAll) {
      right: -60px;
      bottom: -40px;
    }

    @include media(desktopHuge) {
      width: 251px;
      height: 167px;
      bottom: -60px;
      right: -80px;
    }
  }
}

.articleInfo {
  @include setProperty(color, var(--secondary-200), var(--secondary-400));
  font-size: 12px;
  line-height: 18px;
  letter-spacing: var(--ls-183);
  display: flex;
  align-items: center;
  justify-content: center;

  @include media(tablet) {
    font-size: 14px;
  }

  @include media(desktopAll) {
    justify-content: flex-start;
  }

  @include media(desktopAll) {
    font-size: 14px;
  }

  @include media(desktopHuge) {
    font-size: 20px;
    line-height: 1;
  }

  > *:not(:last-of-type) {
    position: relative;
    margin-right: 64px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: -52px;
      width: 40px;
      height: 1px;
      @include setProperty(background, var(--secondary-300), var(--secondary-100));
    }
  }
}

.articleDescription {
  text-align: center;
  font-size: 14px;
  line-height: 2;
  margin-top: var(--margin-md);

  @include media(portrait) {
    margin-top: var(--margin-lg);
  }

  @include media(landscape) {
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media(desktopAll) {
    margin-top: 0;
    text-align: left;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }
  }
}

.titleBlock {
  margin: 40px 0;

  @include media(portrait) {
    margin: 56px 0;
  }

  @include media(landscape) {
    margin: var(--margin-xxl) 0;
  }

  @include media(desktopAll) {
    margin: var(--margin-xxl) 0;
  }

  @include media(desktopHuge) {
    margin: 72px 0;
  }
}

.title {
  font-size: 24px;
  font-family: 'KharkivTone', sans-serif;
  line-height: 32px;
  text-align: center;

  @include media(portrait) {
    font-size: 32px;
    line-height: 54px;
    letter-spacing: 1px;
  }

  @include media(landscape) {
    font-size: 40px;
    line-height: 52px;
    letter-spacing: 1px;
    text-align: left;
    width: 620px;
    margin: 0 auto;
  }

  @include media(desktopSmall) {
    font-size: 56px;
    line-height: 76px;
  }

  @include media(desktop) {
    font-size: 56px;
    line-height: 76px;
  }

  @include media(desktopBig) {
    font-size: 56px;
    line-height: 76px;
  }

  @include media(desktopHuge) {
    font-size: 78px;
    line-height: 112px;
  }

  @include media(desktopAll) {
    text-align: left;
  }

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}

.titleButton {
  display: none !important;
  vertical-align: middle;
  width: 120px !important;

  @include media(landscape) {
    margin-bottom: 6px;
  }

  @include media(tablet) {
    display: inline-flex !important;
  }

  @include media(desktopAll) {
    margin-bottom: 6px;
    display: inline-flex !important;
  }

  @include media(desktopHuge) {
    margin-bottom: 16px;
  }
}

.authorBlock {

  @include media(desktopAll) {
    display: grid;
    gap: var(--margin-lg);
    align-items: flex-start;
  }

  @include media(desktopSmall) {
    grid-template-columns: 291px minmax(0, 1fr);
  }

  @include media(desktop) {
    grid-template-columns: 303px minmax(0, 1fr);
  }

  @include media(desktopBig) {
    grid-template-columns: 319px minmax(0, 1fr);
  }

  @include media(desktopHuge) {
    grid-template-columns: 433px minmax(0, 1fr);
    gap: var(--margin-xxl);
  }
}

.articleInfo,
.titleBlock,
.authorBlock {
  @include media(desktopSmall) {
    width: 863px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media(desktop) {
    width: 863px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media(desktopBig) {
    width: 915px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media(desktopHuge) {
    width: 1104px;
    margin-left: auto;
    margin-right: auto;
  }
}

.imageBlock {
  width: 100%;
  margin: var(--margin-lg) 0;
  display: flex; // need to avoid margin collapsing
  flex-direction: column;

  @include media(tablet) {
    margin: var(--margin-xl) 0;
  }

  @include media(desktopSmall) {
    margin: 56px 0;
  }

  @include media(desktop) {
    margin: 56px 0;
  }

  @include media(desktopBig) {
    margin: var(--margin-xxl) 0;
  }

  @include media(desktopHuge) {
    margin: 72px 0;
  }

  figcaption {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: var(--ls-183);
    @include setProperty(color, var(--secondary-200), var(--secondary-300));
    font-weight: 300;
    padding-top: 8px;
  }
}

.image {
  position: relative;
  height: auto;
  width: 100%;
  margin: var(--margin-sm) 0;

  img {
    width: 100%;
    height: auto;
  }
}

.articleSection {
  scroll-margin: -1px;

  &:not(:first-of-type) {
    margin-top: 36px;
    padding-top: 20px;

    @include media(tablet) {
      margin-top: 44px;
      padding-top: 20px;
    }

    @include media(desktopSmall) {
      margin-top: 84px;
      padding-top: 20px;
    }

    @include media(desktop) {
      margin-top: 138px;
      padding-top: 20px;
    }

    @include media(desktopBig) {
      margin-top: 138px;
      padding-top: 20px;
    }

    @include media(desktopHuge) {
      margin-top: 138px;
      padding-top: 20px;
    }
  }
}

.sectionTitle {
  font-size: 20px;
  line-height: 28px;
  font-family: 'KharkivTone', sans-serif;
  text-transform: capitalize;
  margin-bottom: 32px;

  @include media(tablet) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  @include media(desktopAll) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: var(--ls-183);
    margin-bottom: 56px;
  }

  @include media(desktopHuge) {
    font-size: 40px;
    line-height: 56px;
  }
}

.text {
  font-size: 14px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: var(--ls-183);
  margin: 28px 0;
  white-space: break-spaces;

  @include media(desktopAll) {
    font-size: 16px;
  }

  @include media(desktopSmall) {
    font-size: 14px;
  }

  @include media(desktopBig) {
    margin: var(--margin-lg) 0;
  }

  @include media(desktopHuge) {
    font-size: 24px;
    line-height: 38px;
    margin: var(--margin-lg) 0;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
  }

  code {
    display: block;
    @include setProperty(background, var(--secondary-500), var(--secondary-50));
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    padding: 8px 12px;
    border-radius: 4px;
  }

  ul {
    font-weight: 300;
    letter-spacing: var(--ls-183);
    margin-bottom: var(--margin-lg);

    li {
      padding-left: 20px;
      position: relative;

      &:before {
        content: '•';
        position: absolute;
        left: 8px;
      }
    }
  }

  ol {
    margin: 0 0 var(--margin-lg);
    padding-left: 0;

    li {
      list-style: decimal;
      list-style-position: inside;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.articleCarousel {
  margin: var(--margin-lg) 0;

  @include media(desktopAll) {
    position: relative;
    z-index: 2;
  }

  @include media(tablet) {
    margin: var(--margin-xl) 0;
  }

  @include media(desktopSmall) {
    margin: 56px 0;
  }

  @include media(desktop) {
    margin: 56px 0;
  }

  @include media(desktopBig) {
    margin: var(--margin-xxl) 0;
  }

  @include media(desktopHuge) {
    margin: 72px 0;
  }

  .track {
    overflow: visible !important;

    @include media(desktopAll) {
      clip-path: inset(-100vw -100vw -100vw 0);
    }
  }

  .navigation {
    display: none;

    @include media(desktopAll) {
      display: flex;
      gap: 28px;
      margin-top: 56px;
    }

    .arrow {
      cursor: default;
      display: none;
      width: 84px;
      height: 58px;
      position: relative;
      background-color: transparent;
      border-radius: 0;

      @include media(desktopAll) {
        display: block;
        transform: none;
      }

      &_prev {
        left: auto;

        img {
          transform: rotate(180deg);
        }
      }

      &_next {
        right: auto;
      }
    }
  }
}

.articleCarouselImage {
  height: 224px;

  img {
    width: auto;
    height: 100%;
  }

  @include media(portrait) {
    height: 394px;
  }

  @include media(landscape) {
    height: 394px;
  }

  @include media(desktopSmall) {
    height: 497px;
  }

  @include media(desktop) {
    height: 542px;
  }

  @include media(desktopBig) {
    height: 583px;
  }

  @include media(desktopHuge) {
    height: 985px;
  }
}

.allButton {
  margin: 8px auto 0;

  @include media(tablet) {
    display: none !important;
  }

  @include media(desktopAll) {
    display: none !important;
  }
}

.articleMenuWrap {
  position: relative;

  @include media(tablet) {
    margin: 0 auto;
  }

  @include media(portrait) {
    width: 444px;
  }

  @include media(landscape) {
    width: 544px;
  }
}

.articleMenu {
  padding: var(--margin-lg) var(--margin-md);
  display: flex;
  flex-direction: column;
  gap: 18px;
  border: 1px solid;
  @include setProperty(border-image, (linear-gradient(90deg, rgba(42, 54, 52, 0.8), rgba(42, 54, 52, 0.5), rgba(42, 54, 52, 0.1)) 10), (linear-gradient(90deg, rgba(163, 163, 163, 0.8), rgba(163, 163, 163, 0.5), rgba(163, 163, 163, 0.1)) 10));
  margin-bottom: var(--margin-xxl);

  @include media(tablet) {
    margin-bottom: 80px;
  }

  @include media(desktopAll) {
    margin-bottom: 0;
    position: sticky;
    top: 16px;
  }

  @include media(desktopHuge) {
    padding: 40px;
  }
}

.articleAnchor {
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  font-size: 14px;
  line-height: 22px;
  letter-spacing: var(--ls-183);
  font-weight: 500;
  text-align: left;
  transition: color 0.3s ease;
  position: relative;

  @include media(desktopAll) {
    margin-bottom: 8px;
  }

  @include media(desktopHuge) {
    font-size: 20px;
    line-height: 32px;
  }

  &:hover {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  &:active {
    @include setProperty(color, var(--primary-200), var(--primary-500));
  }

  &.active {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  @include media(tablet) {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .progress {
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 100%;
    height: 1px;
    @include setProperty(background, var(--secondary-500), var(--secondary-50));

    &__marker {
      position: absolute;
      width: 3px;
      height: 3px;
      @include setProperty(background, var(--primary-500), var(--primary-900));
      border-radius: 50%;
      top: -1px;
    }
  }
}

.listTitle {
  font-size: 18px;
  line-height: 32px;
  letter-spacing: var(--ls-183);
  font-weight: 600;
  margin-bottom: var(--margin-md);

  @include media(portrait) {
    font-size: 20px;
    line-height: 34px;
    margin-bottom: var(--margin-sm);
  }
}

.untitledList {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.blockquote {
  padding: var(--margin-lg) var(--margin-md);
  position: relative;
  margin: var(--margin-lg) 0;

  @include media(tablet) {
    padding: var(--margin-xl);
    margin: var(--margin-xl) 0;
  }

  @include media(desktopAll) {
    padding: var(--margin-xl);
  }

  @include media(desktopSmall) {
    margin: 56px 0;
  }

  @include media(desktop) {
    margin: 56px 0;
  }

  @include media(desktopBig) {
    margin: var(--margin-xxl) 0;
  }

  @include media(desktopHuge) {
    margin: 72px 0;
  }

  .icon {
    @include setProperty(color, var(--secondary-800), var(--secondary-50));
    position: absolute;
    width: 70px;
    height: 70px;
    z-index: 1;
    left: 24px;
    top: 0;
  }

  .blockquoteText {
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    margin: 0 0 28px;
    position: relative;
    z-index: 1;

    @include media(desktopAll) {
      line-height: 155%;
      letter-spacing: var(--ls-183);
    }

    @include media(desktopSmall) {
      font-size: 24px;
      margin-bottom: 28px;
    }

    @include media(desktop) {
      font-size: 24px;
      margin-bottom: 28px;
    }

    @include media(desktopBig) {
      font-size: 24px;
      margin-bottom: var(--margin-lg);
    }

    @include media(desktopHuge) {
      font-size: 28px;
      margin-bottom: var(--margin-xl);
    }
  }

  figcaption {
    line-height: 20px;
    position: relative;
    padding-left: 44px;

    &:before {
      content: '';
      position: absolute;
      width: 33px;
      height: 1px;
      top: 10px;
      left: 0;
      @include setProperty(background, var(--secondary-50), var(--secondary-500));
      z-index: 0;

      @include media(tablet) {
        width: 38px;
      }

      @include media(desktopAll) {
        width: 38px;
      }
    }
  }

  .authorName {
    font-size: 14px;
    margin-right: 6px;
    font-style: normal;
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  .authorPosition {
    font-size: 12px;
    font-weight: 300;
    display: inline-block;
  }
}

.followBlockDesktop {
  display: none;

  @include media(desktopAll) {
    display: block;
    position: relative;

  }

  @include media(desktopSmall) {
    width: 274px;

    &__image {
      position: relative;
      left: -20px;
    }
  }
}

.followBlockMobile {
  display: block;
  position: relative;
  margin-top: var(--margin-lg);

  @include media(desktopAll) {
    display: none;
  }
}

.socialBar {
  border-radius: 48px;
  overflow: hidden;
  @include setProperty(background, var(--dark-bg), var(--light-bg));
  border: 1px solid;
  position: sticky;
  @include setProperty(border-color, var(--dark-line-accent), var(--light-line-accent));
  bottom: 16px;
  z-index: 1;

  @include media(desktopAll) {
    background: none;
    position: sticky;
    top: 16px;
    box-shadow: none;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    height: 48px;
    border-radius: 48px;

    @include media(tablet) {
      height: 56px;
    }

    @include media(desktopAll) {
      display: block;
      height: auto;
      width: 48px;
      background-color: transparent;
    }

    @include media(desktopHuge) {
      width: 64px;
    }
  }

  .social {
    @include setProperty(color, var(--primary-500), var(--secondary-300));
    display: flex;
    align-items: center;
    justify-content: center;

    @include media(desktopAll) {
      padding: 16px 0;
      width: 100%;
      height: 70px;
    }

    @include media(desktopHuge) {
      padding: 32px 0;
    }

    svg {
      width: 20px;
      height: 20px;
      opacity: 0.7;

      @include media(desktopHuge) {
        height: 40px;
        width: 40px;
      }
    }

    &:hover {
      @include setProperty(background, var(--secondary-700), var(--secondary-100));

      svg {
        opacity: 1;
        transform: scale(1.1);
      }
    }

    &:not(:last-child) {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 1px;
        @include setProperty(background, var(--dark-line-accent), var(--light-line-accent));

        @include media(desktopAll) {
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;
          top: auto;
          right: auto;
        }
      }
    }
  }
}

.conclusion {
  padding: var(--margin-lg) var(--margin-md) 40px;
  margin: var(--margin-lg) 0 0;
  border: 1px solid;
  @include setProperty(border-image, (linear-gradient(90deg, rgba(42, 54, 52, 0.8), rgba(42, 54, 52, 0.5), rgba(42, 54, 52, 0.1)) 10), (linear-gradient(90deg, rgba(163, 163, 163, 0.8), rgba(163, 163, 163, 0.5), rgba(163, 163, 163, 0.1)) 10));
  font-size: 16px;
  line-height: 2;

  @include media(mobile) {
    margin-bottom: var(--margin-lg);
  }

  @include media(tablet) {
    padding: 38px 58px 58px;
    margin: var(--margin-xl) 0;
    font-size: 20px;
  }

  @include media(desktopAll) {
    margin-bottom: 0;
  }

  @include media(desktopSmall) {
    margin-top: 56px;
  }

  @include media(desktop) {
    margin-top: 56px;
  }

  @include media(desktopBig) {
    margin-top: var(--margin-xxl);
  }

  @include media(desktopHuge) {
    padding: 38px 58px 58px;
    margin-top: 72px;
    font-size: 28px;
  }

  &__title {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: var(--ls-183);
    font-weight: bold;
    margin-bottom: 18px;
  }

  &__text {
    letter-spacing: var(--ls-183);
    font-weight: 300;
    font-size: inherit;
    line-height: inherit;
  }
}

.relatedNews {
  padding-bottom: 40px;
  margin-top: 80px;

  @include media(portrait) {
    padding-bottom: 104px;
  }

  @include media(landscape) {
    padding-bottom: 56px;
  }

  @include media(desktopAll) {
    padding-bottom: 104px;
  }

  &__line {
    display: none;
    @include setProperty(background, var(--dark-line-low-accent), var(--light-line-low-accent));
    min-width: 20px;

    @include media(tablet) {
      display: block;
      height: 1px;
    }

    @include media(desktopAll) {
      display: block;
      height: 1px;
    }
  }

  &__header {
    position: relative;

    @include media(tablet) {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }

    @include media(desktopAll) {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }

  &__categories {
    @include hideScrollbar;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    @include media(mobile) {
      overflow-x: auto;
      overflow-y: hidden;
      margin-right: calc(var(--margin-md) * -1);
      padding-right: var(--margin-md);
      margin-left: calc(var(--margin-md) * -1);
      padding-left: var(--margin-md);
    }

    @include media(tablet) {
      flex-wrap: wrap;
    }
  }

  &__content {
    display: flex;
    align-items: stretch;
    gap: var(--margin-md);
    @include scrollOutOfContainer;
    @include hideScrollbar;
    margin-top: var(--margin-lg);

    @include media(landscape) {
      gap: var(--margin-xl)
    }

    @include media(desktopAll) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include media(desktopSmall) {
      gap: 48px;
    }

    @include media(desktop) {
      gap: 52px;
    }

    @include media(desktopBig) {
      gap: 64px;
    }

    @include media(desktopHuge) {
      gap: 84px;
    }
  }

  &__item {
    flex-shrink: 0;
    width: 316px;

    @include media(portrait) {
      width: 368px;
    }

    @include media(landscape) {
      width: 448px;
    }

    @include media(desktopAll) {
      width: auto;
    }
  }

  &__moreButton {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-size: 12px;
    line-height: 1;
    letter-spacing: 1px;
    padding: 18px 0;
    text-decoration: underline;
    width: fit-content;
    display: none;

    @include media(tablet) {
      display: block;
      font-size: 14px;
      flex-shrink: 0;
    }

    @include media(desktopAll) {
      display: block;
    }

    &_mobile {
      display: block;
      margin: 32px auto 0;

      @include media(tablet) {
        display: none;
      }

      @include media(desktopAll) {
        display: none;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }
}

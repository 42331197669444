@import "src/styles/helpers";

.components {
  padding-top: var(--margin-xxl);
  padding-bottom: var(--margin-xxl);

  @include media(tablet) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @include media(desktopAll) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  &__content {
    display: grid;
    gap: 72px;
    margin-top: 56px;

    @include media(landscape) {
      gap: 100px;
    }

    @include media(desktopAll) {
      gap: 164px;
    }
  }
}

.title {

  @include media(tablet) {
    font-size: 32px;
    line-height: 40px;
  }
}

.component {
  display: grid;
  align-items: flex-start;

  &_single {
    border-top: 1px solid;
    @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
    padding-top: var(--margin-xl);

    @include media(landscape) {
      grid-template-columns: 1fr 50%;
      gap: 60px;
    }

    @include media(desktopAll) {
      grid-template-columns: 1fr 50%;
      gap: 60px;
    }

    &:nth-child(even) {

      @include media(landscape) {
        .component {

          &__content {
            order: 1;
          }
        }
      }

      @include media(desktopAll) {
        .component {

          &__content {
            order: 1;
          }
        }
      }
    }

    .component {

      &__image {
        //height: 340px;
      }
    }
  }

  &_double {
    gap: 60px;

    @include media(landscape) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media(desktopAll) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .component {

      &__image {
        //height: 360px;

        @include media(landscape) {
          margin-top: 40px;
        }
      }
    }

    &__column {

      &:first-of-type {

        @include media(landscape) {
          display: grid;

          .columnContent {
            order: 1;
            padding-top: 40px;
            margin-top: 40px;
          }
        }

        @include media(desktopAll) {
          display: grid;

          .columnContent {
            order: 1;
            padding-top: 40px;
            margin-top: 40px;
          }
        }
      }

      &:last-of-type {
        border-top: 1px solid;
        @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
        padding-top: 48px;
      }
    }
  }

  &__title {
    margin-bottom: var(--margin-sm);

    @include media(mobile) {
      font-size: 20px;
      line-height: 28px;
    }

    @include media(tablet) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__text {
    font-size: 16px;
    margin: 0;

    ul {
      margin: 0;
    }
  }

  &__image {
    margin-top: 60px;
    position: relative;
    //@include imgCover;

    img {
      width: 100%;
      height: auto;
    }

    @include media(landscape) {
      margin-top: 0;
    }

    @include media(desktopAll) {
      margin-top: 0;
    }
  }
}

@import "src/styles/helpers";

.submenuContainer {
  transition: height 0.3s ease;
  overflow: hidden;

  @include media(landscape) {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    justify-content: space-between;
  }

  .submenu {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 10px 24px 16px 24px;
    @include setProperty(background-color, var(--dark-bg), var(--light-bg));
    transition: background 0.3s ease;
    overflow: auto;

    @include media(landscape) {
      margin-top: $mobile-header-height;
      padding: 32px 32px 0;
      flex-grow: 1;
    }

    &Active {
      @include setProperty(
        background,
        var(--secondary-800),
        var(--light-line-low-accent)
      );

      &.isCareerBg {
        background: transparent;
      }
    }
  }

  .allServicesButton {
    width: 100% !important;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    @include setProperty(
      background,
      linear-gradient(
        90deg,
        rgba(247, 255, 254, 0) -33.37%,
        rgba(155, 188, 183, 0.1224) 35.32%,
        rgba(247, 255, 254, 0) 118.57%
      ),
      linear-gradient(
        89.31deg,
        rgba(247, 255, 254, 0) -60.24%,
        rgba(155, 188, 183, 0.255) 16.84%,
        rgba(247, 255, 254, 0) 151.32%
      )
    );

    @include media(landscape) {
      height: 76px;
    }
  }
}

@import "src/styles/helpers";

.chip {
  display: inline-block;
  padding: 12px 24px;
  text-align: center;
  border-radius: 63px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: var(--ls-183);
  border: 1px solid transparent;
  @include setProperty(background, var(--secondary-800), var(--secondary-50));
  @include setProperty(color, var(--secondary-50), var(--secondary-500));

  &:hover {
    @include setProperty(background, var(--secondary-600), var(--secondary-100));
  }

  &:active {
    @include setProperty(background, var(--secondary-700), var(--secondary-50));
    @include setProperty(color, var(--secondary-50), var(--secondary-300));
  }

  &.active {
    @include setProperty(background, var(--secondary-600), var(--secondary-50));
    @include setProperty(border-color, var(--secondary-50), var(--secondary-500));
  }

  &.disabled {
    pointer-events: none;
    @include setProperty(background, var(--secondary-400), var(--secondary-100));
    @include setProperty(color, var(--secondary-300), var(--secondary-200));
  }
}

@import "src/styles/helpers";

.wrapper {
  padding: 0 24px 64px;

  @include media(portrait) {
    padding: 0 64px 104px;
  }

  @include media(landscape) {
    padding: 0 72px 120px;
  }

  @include media(desktopAll) {
    padding-left: 0;
    padding-right: 0;
  }
}
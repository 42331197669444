@mixin scaleImg {
  overflow: hidden;

  img {
    transition: all 0.3s ease;
  }

  &:hover img {
    transform: scale(1.05);
  }

  &:active {
    opacity: 0.7;
  }
}

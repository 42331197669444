@import 'src/styles/helpers';

.article {

  @include media(tablet) {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 28px;
  }

  @include media(desktopAll) {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 28px;
  }

  @include media(desktopHuge) {
    gap: 48px;
  }

  &.verticalLayout {
    display: block;

    .image {
      height: 240px;
      margin-bottom: 28px;
    }

    .title {
      font-size: 20px;
      line-height: 28px;
    }

    .info {
      font-size: 12px;
      line-height: 1;
    }

    .category {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &.minifiedLayout {
    grid-template-columns: 84px 1fr;
    gap: 16px;

    .image {
      width: 84px;
      height: 84px;
    }

    .title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
      margin-top: 0;
    }

    .info {
      margin-top: 8px;

      > *:not(:last-child) {
        margin-right: 20px;

        &:after {
          content: '/';
          position: absolute;
          right: -12px;
        }
      }
    }
  }

  &_searchView {

    @include media(tablet) {
      height: 168px;
      gap: var(--margin-xl);
      grid-template-columns: 260px 1fr;
      align-items: center;
    }

    @include media(desktopAll) {
      height: 168px;
      gap: var(--margin-xl);
      grid-template-columns: 260px 1fr;
      align-items: center;
    }

    .image {
      height: 168px;

      @include media(tablet) {
        height: 100%;
      }

      @include media(desktopAll) {
        height: 100%;
      }
    }

    .title, .info {
      color: var(--secondary-200) !important;
    }

    .title {
      font-size: 16px;
      line-height: 24px;

      @include media(desktopAll) {
        font-size: 20px;
        line-height: 28px;
      }

      &:hover {
        @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
        text-decoration: none;
      }
    }

    .author {

      &:after {
        pointer-events: none;
      }

      &:hover {
        @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);

        &:after {
          color: var(--secondary-200) !important;
        }
      }
    }

    .category {
      @include setProperty(color, var(--primary-900), var(--primary-500));

      &:hover {
        @include setProperty(color, var(--primary-500), var(--primary-900));
      }
    }
  }
}

.image {
  position: relative;
  height: 240px;
  width: 100%;
  margin-bottom: 28px;
  display: block;
  @include imgCover;
  @include scaleImg;

  @include media(portrait) {
    height: 240px;
  }

  @include media(landscape) {
    height: 254px;
  }

  @include media(tablet) {
    margin-bottom: 0;
  }

  @include media(desktopSmall) {
    height: 204px;
  }

  @include media(desktop) {
    height: 206px;
  }

  @include media(desktopBig) {
    height: 216px;
  }

  @include media(desktopHuge) {
    height: 284px;
  }

  @include media(desktopAll) {
    margin-bottom: 0;
  }
}

.category {
  @include setProperty(color, var(--primary-500), var(--primary-900));
  font-size: 12px;
  line-height: 14px;
  font-family: 'KharkivTone', sans-serif;
  text-transform: lowercase;
  display: block;
  width: fit-content;

  @include media(desktopHuge) {
    font-size: 16px;
    line-height: 20px;
  }

  &:before {
    content: '.'
  }

  &:hover {
    text-decoration: underline;
  }
}

.title {
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  font-size: 20px;
  line-height: 28px;
  letter-spacing: var(--ls-183);
  font-family: 'KharkivTone', sans-serif;
  margin-bottom: 16px;
  margin-top: 8px;
  display: block;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  transition: color 0.3s ease;

  &:hover {
    text-decoration: underline;
  }

  @include media(tablet) {
    font-size: 24px;
    line-height: 32px;
  }

  @include media(desktopAll) {
    font-size: 24px;
    line-height: 32px;
  }

  @include media(desktopHuge) {
    font-size: 28px;
    line-height: 34px;
    margin-top: var(--margin-sm);
  }
}

.text {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));

  @include media(tablet) {
    -webkit-line-clamp: 2;
  }

  @include media(desktopAll) {
    -webkit-line-clamp: 2;
  }

  @include media(desktopHuge) {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;
  }
}

.info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  letter-spacing: var(--ls-183);
  margin-top: var(--margin-sm);
  @include setProperty(color, var(--secondary-50), var(--secondary-500));

  @include media(desktopHuge) {
    font-size: 16px;
    line-height: 20px;
  }

  > *:not(:last-child) {
    position: relative;
    margin-right: 28px;

    &:after {
      content: '/';
      position: absolute;
      right: -16px;
    }
  }
}

.author {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

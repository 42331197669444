@import "src/styles/helpers";

.feature {
  margin: 0 auto;
  padding: 64px 24px;
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;

  &__block {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    gap: 48px;

    &:first-of-type {
      margin-top: 0;
    }

    .images {
      margin-top: 0 !important;
    }
  }

  @include media(portrait) {
    padding: 104px 64px;
  }

  @include media(landscape) {
    padding: 120px 72px;
    row-gap: 92px;
  }

  @include media(desktopAll) {
    padding: 120px 0;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1.5fr);
    column-gap: 120px;
    row-gap: 92px;
  }

  &__onLeft {
    .title {
      @include media(desktopAll) {
        grid-column: 1/3;
        grid-row: 1/2;
      }
    }

    .descriptions {
      @include media(desktopAll) {
        grid-column: 2/3;
        grid-row: 2/3;
      }
    }

    .images {
      @include media(desktopAll) {
        grid-column: 1/2;
        grid-row: 2/3;
      }
    }

    @include media(desktopAll) {
      align-items: center;
      grid-template-columns: minmax(0, 1.2fr) minmax(0, 1fr);
    }
  }

  &__onRight {
    .title {
      @include media(desktopAll) {
        grid-column: 1/3;
        grid-row: 1/2;
      }
    }

    .descriptions {
      @include media(desktopAll) {
        grid-column: 1/2;
        grid-row: 2/3;
      }
    }

    .images {
      @include media(desktopAll) {
        grid-column: 2/3;
        grid-row: 2/3;
      }
    }

    @include media(desktopAll) {
      align-items: center;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1.2fr);
    }
  }

  &__fullWidth {
    @include media(landscape) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1.2fr);
      column-gap: 48px;
      row-gap: 92px;
    }


    @include media(desktopAll) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1.2fr);
      column-gap: 48px;
      row-gap: 92px;
    }

    .title {
      @include media(landscape) {
        grid-column: 1/2;
      }

      @include media(desktopAll) {
        grid-column: 1/2;
      }
    }

    .descriptions {
      @include media(landscape) {
        grid-column: 2/3;
      }

      @include media(desktopAll) {
        grid-column: 2/3;
      }
    }

    .images {
      @include media(landscape) {
        grid-column: 1/3;
      }

      @include media(desktopAll) {
        grid-column: 1/3;
        min-height: 387px;
      }
    }
  }


  .title {
    font-size: 28px;
    line-height: 36px;

    @include media(landscape) {
      font-size: 40px;
      line-height: 48px;
    }

    @include media(desktopAll) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  .descriptions {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 2;

    @include media(landscape) {
      font-size: 16px;
      letter-spacing: var(--ls-183);
    }

    @include media(desktopAll) {
      font-size: 16px;
      letter-spacing: var(--ls-183);
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      li {
        display: flex;

        &:before {
          content: "•";
          padding: 0 9px;
          height: fit-content;
        }
      }

      @include media(landscape) {
        margin-top: 0;

        li {
          &:before {
            padding: 0 11px;
          }
        }
      }

      @include media(desktopAll) {
        margin-top: 0;

        li {
          &:before {
            padding: 0 11px;
          }
        }
      }
    }
  }


  .images {
    margin-top: 22px;
    width: 100%;

    @include media(desktopAll) {
      margin: 0 auto;
    }
  }
}
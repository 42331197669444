@import 'src/styles/helpers';

.toggle {
  @include setProperty(background, (rgba(130, 178, 170, 0.14)), (rgba(173, 173, 173, 0.40)));
  border: 1px solid transparent;
  border-radius: 120px;
  width: 40px;
  height: 20px;
  padding: 0 2px;

  * {
    transition: all 0.3s ease;
  }

  &:hover {
    border-color: rgba(107, 216, 199, 0.20);

    .particles {

      &__shadow {
        left: -20px;
      }
    }
  }

  &_light {

    .circle {
      background-color: var(--secondary-500);
      margin-left: 18px;
    }

    &:hover {
      border-color: var(--light-line-accent);

      .circle {

        .particles {
          transform: translate(3px, -3px);
        }
      }
    }
  }
}

.circle {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  svg, img {
    width: 100%;
    height: 100%;
  }
}

.particles {

  &__shadow {
    position: absolute;
    left: -2px;
    bottom: 0;
    transform-origin: bottom right;
  }
}

@import "src/styles/helpers";

.solutions {
  counter-reset: solution;
  padding-top: var(--margin-xxl);
  padding-bottom: var(--margin-xxl);

  @include media(tablet) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @include media(desktopAll) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  &__content {
    margin-top: 56px;
    display: grid;
    gap: 16px;

    @include media(portrait) {
      grid-auto-rows: 1fr;
    }

    @include media(landscape) {
      margin-top: 92px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media(desktopAll) {
      margin-top: 92px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-auto-rows: 1fr;
    }
  }
}

.title {
  font-size: 28px;
  line-height: 36px;

  @include media(landscape) {
    font-size: 40px;
    line-height: 48px;
  }

  @include media(desktopAll) {
    font-size: 40px;
    line-height: 48px;
  }
}

.solution {
  @include setProperty(border, 1px solid var(--dark-line-low-accent), 1px solid var(--light-line-low-accent));
  padding: var(--margin-md);
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  font-size: 14px;
  line-height: 2;
  font-weight: 300;

  @include media(tablet) {
    padding: var(--margin-xl);
  }

  @include media(desktopAll) {
    font-size: 16px;
  }

  &:before {
    font-size: 16px;
    font-weight: normal;
    counter-increment: solution;
    content: counter(solution);
    margin-right: 24px;
    border-radius: 50%;
    @include setProperty(border, 1px solid var(--primary-500), 1px solid var(--primary-900));
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}
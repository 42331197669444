@import "src/styles/helpers";

.author {
  display: flex;
  align-items: center;
  gap: 16px;
  letter-spacing: var(--ls-183);

  &.vertical {
    flex-direction: column;
    text-align: center;
  }

  &__avatar {
    border-radius: 50%;
    overflow: hidden;
    @include imgCover;
    width: 68px;
    height: 68px;
    position: relative;
    flex-shrink: 0;
    @include setProperty(background, var(--secondary-50), var(--secondary-100));
    display: block;

    img {
      transition: transform 0.3s ease;
    }
  }

  &__name {
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 8px;
    display: inline-block;
    transition: color 0.3s ease;
  }

  &__info {
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;

    @at-root .vertical & {
      justify-content: center;
    }
  }
}

a.author__name:hover {
  text-decoration: underline;
}

a.author__avatar:hover {
  img {
    transform: scale(1.1);
  }
}

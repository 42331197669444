@import 'src/styles/helpers';

.infoCard {
  display: grid;
  gap: 24px;
  align-items: center;

  &:nth-child(even) {
    @include media(landscape) {
      .image {
        order: 1;
      }
    }

    @include media(desktopAll) {
      .image {
        order: 1;
      }
    }
  }

  @include media(portrait) {
    gap: 24px;
  }

  @include media(landscape) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    gap: 64px;
  }

  @include media(desktopAll) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    gap: 64px;
  }

  .image {
    img {
      transition: transform .3s ease;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }
}

.image {
  display: flex;
  align-items: center;
  @include imgCover;
  height: 327px;
  overflow: hidden;
  position: relative;

  @include media(portrait) {
    height: 388px;
  }
}

.content {
  padding-bottom: 40px;
  display: grid;
  gap: 24px;

  @include media(portrait) {
    padding-bottom: 24px;
  }

  @include media(landscape) {
    padding: 24px 0;
  }

  @include media(desktopAll) {
    padding: 24px 0;
  }
}

.subTitle {
  @include setProperty(color, var(--primary-500), var(--primary-900));
  font-size: 16px;
  line-height: 20px;
  letter-spacing: var(--ls-183);
  text-transform: uppercase;
  margin-bottom: var(--margin-xs);
  font-weight: bold;

  @include media(tablet) {
    margin-bottom: 12px;
  }

  @include media(desktopAll) {
    margin-bottom: 12px;
  }
}

.title {
  display: inline-block;
  font-family: 'KharkivTone', sans-serif;
  font-size: 24px;
  line-height: 32px;

  @include media(desktopAll) {
    font-size: 32px;
    line-height: 40px;
  }
}

.description {
  padding-top: 8px;
  font-size: 14px;
  line-height: 2;
  font-weight: 300;

  white-space: normal;

  @include media(desktopAll) {
    padding-top: 16px;
    font-size: 16px;
  }
}

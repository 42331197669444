@import "src/styles/helpers";

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; // fallback
  height: calc(var(--vh, 1vh) * 100);
  @include setProperty(background-color, var(--dark-bg), var(--light-bg));
  z-index: 10;

  @include media(landscape) {
    display: grid;
    grid-template-columns: 360px 1fr;
  }

  @include media(desktopAll) {
    display: none;
  }
}

.header {
  position: absolute;
  z-index: 1;
  left: 0;
  height: $mobile-header-height;
  right: 0;
  @include setProperty(background-color, var(--dark-bg), var(--light-bg));
  border-bottom: 1px solid;
  @include setProperty(
    border-color,
    var(--secondary-600),
    var(--light-line-low-accent)
  );

  .logo {
    position: absolute;
    left: var(--margin-md);
    top: 20px;
  }

  .closeButton {
    position: absolute;
    right: 28px;
    top: 20px;
    width: 24px;
    height: 24px;
    @include setProperty(color, var(--secondary-200), var(--secondary-400));
    transition: color 0.3s ease;

    &:hover {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }

    @include media(landscape) {
      width: 40px;
      height: 40px;
      right: 14px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.menuLink {
  font-family: "KharkivTone", sans-serif;
  font-size: 28px;
  line-height: 34px;
  text-transform: capitalize;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  transition: all 0.3s ease;
  position: relative;
  padding: 20px 32px;

  &:before {
    content: "";
    position: absolute;
    left: 32px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    @include setProperty(
      background,
      var(--dark-elipse-grain),
      var(--light-elipse-grain)
    );
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  @include media(landscape) {
    svg {
      transform: rotate(-90deg);
    }
  }

  &_active {
    padding-left: 52px;
    @include setProperty(color, var(--primary-500), var(--primary-900));

    @include media(landscape) {
      svg {
        transform: rotate(-90deg);
      }
    }

    &:before {
      opacity: 1;
    }

    &:after {
      content: "";
      position: absolute;
      left: 2px;
      top: 6px;
      background: url("/media/submenuTitleImage.svg") no-repeat center;
      width: 60px;
      height: 68px;
    }
  }

  &_submenu_active {
    @include setProperty(
      background,
      var(--secondary-800),
      var(--light-line-low-accent)
    );
    padding-left: 32px;

    &::after,
    &::before {
      content: none;
    }

    svg {
      transform: rotate(-180deg);
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    transition: transform 0.3s ease;

    svg {
      width: 100%;
      height: 100%;
      transition: transform 0.3s;
    }
  }
}

button.menuLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar {
  @include pageHeaderPadding;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  transition: border-color 0.3s;

  @include media(landscape) {
    border-right: 1px solid;
    @include setProperty(
      border-color,
      var(--secondary-600),
      var(--light-line-low-accent)
    );

    &.submenu_active {
      @include setProperty(
        border-color,
        var(--secondary-800),
        var(--light-line-low-accent)
      );
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-bottom: 38px;
    margin-top: 32px;

    @include media(landscape) {
      margin-top: 0;
    }
  }
}

.main {
  position: relative;
  overflow: auto;

  &__content {
    opacity: 0;
    position: relative;
    z-index: 0;

    @keyframes menuSlideDown {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
        padding-top: var(--margin-xl);
      }
    }

    @keyframes menuSlideUp {
      from {
        opacity: 1;
        padding-top: var(--margin-xl);
      }

      to {
        opacity: 0;
        padding-top: 0;
      }
    }

    @keyframes menuShowDesktop {
      from {
        opacity: 0;
        margin-left: -38px;
      }

      to {
        opacity: 1;
        margin-left: 0;
      }
    }

    @keyframes menuHideDesktop {
      from {
        opacity: 1;
        margin-left: 0;
      }

      to {
        opacity: 0;
        margin-left: -38px;
      }
    }

    &_visible {
      display: block;
      height: 100vh; // fallback
      height: calc(var(--vh, 1vh) * 100);
      width: 100%;
      animation: menuSlideDown 0.7s ease forwards;

      @include media(landscape) {
        animation-name: menuShowDesktop;
      }
    }

    &_hidden {
      animation: menuSlideUp 0.7s ease forwards;

      @include media(landscape) {
        animation-name: menuHideDesktop;
      }
    }
  }
}

.closeSubmenuButton {
  font-size: 16px;
  line-height: 1;
  font-weight: 300;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));

  &:before {
    content: "";
    width: 32px;
    height: 1px;
    display: inline-block;
    vertical-align: middle;
    @include setProperty(
      background,
      var(--secondary-300),
      var(--secondary-600)
    );
    margin-right: 8px;
  }
}

.illustration {
  margin-top: $mobile-header-height;
  position: absolute;
  left: 0;
  top: 0;
  height: calc(100% - $mobile-header-height);
  width: 100%;
  pointer-events: none;
  transition: opacity 0.4s ease;
  @include setProperty(
    background,
    url("/media/noise_bg.png") no-repeat center / cover,
    inherit
  );
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    @include setProperty(
      background,
      var(--secondary-800),
      var(--light-line-low-accent)
    );
  }

  &_hidden {
    opacity: 0;
  }
}

.image {
  width: 80%;
  height: 80%;
  position: relative;

  img {
    object-fit: contain;
  }

  &_contacts {
    width: 100%;
    height: 100%;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: var(--margin-md);
  border-top: 1px solid;
  @include setProperty(
    border-color,
    var(--secondary-600),
    var(--light-line-low-accent)
  );
  position: sticky;
  bottom: 0;
  min-height: 76px;
  left: 0;
  @include setProperty(background, var(--dark-bg), var(--light-bg));

  .themeMode {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
}

.searchButton {
  width: 20px;
  height: 20px;
  @include setProperty(color, var(--secondary-200), var(--secondary-400));

  svg {
    width: 100%;
    height: 100%;
  }
}
